.cookie-overlay {
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(19, 8, 6, 0.6);
  -webkit-transition: all 350ms ease-out;
  -o-transition: all 350ms ease-out;
  transition: all 350ms ease-out;
  overflow: auto;
  z-index: 9999;
  animation: 0.1s ease-in-out darken;
  font-size: 16px;
}
.cookie-overlay h3 {
  line-height: 48px;
  font-size: 32px;
  margin: 0;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  margin-bottom: 0;
}
.cookie-overlay p {
  line-height: 24px;
  margin: 0;
}
.cookie-overlay .cookie-content-inner {
  position: absolute;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 590px;
  padding: 40px;
  border-radius: 16px;
  z-index: 100;
  animation: 0.3s ease-in-out slideFromBottom;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: 1em;
  max-height: 100dvh;
  overflow: auto;
}
.cookie-overlay .info {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.cookie-overlay .checkbox-wrapper label {
  display: flex;
  gap: 0.5em;
  align-items: baseline;
}
.cookie-overlay .checkbox-wrapper input {
  flex-shrink: 0;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid #919183;
  display: grid;
  place-content: center;
}
.cookie-overlay .checkbox-wrapper input:focus {
  outline: 2px solid #6005a5;
  outline-offset: max(1px, 0.15em);
}
.cookie-overlay .checkbox-wrapper input:checked {
  background-color: #6005a5;
  border-color: transparent;
}
.cookie-overlay .checkbox-wrapper input:checked::before {
  transform: scale(1);
}
.cookie-overlay .checkbox-wrapper input::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  background-color: #ffffff;
}
.cookie-overlay .cookie-buttons {
  display: flex;
  gap: 1rem;
}
.cookie-overlay .cookie-buttons button {
  border-radius: 24px;
  background: #6005a5;
  padding: 1em 1.5em;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #ffffff;
  position: relative;
}
.cookie-overlay .cookie-buttons button:hover {
  opacity: 0.9;
}

@keyframes slideFromBottom {
  0% {
    top: 55%;
  }
  100% {
    top: 50%;
  }
}
@keyframes slideFromBottomMobile {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}
@keyframes darken {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Mobile */
@media (max-width: 576px) {
  .cookie-overlay {
    font-size: 13px;
  }
  .cookie-overlay h3 {
    font-size: 20px;
  }
  .cookie-overlay .cookie-content-inner {
    gap: 1.5em;
    position: absolute;
    background-color: white;
    top: initial;
    bottom: 0;
    left: 0;
    transform: none;
    width: 100%;
    padding: 1.5em;
    border-radius: 16px;
    animation: 0.55s ease-in-out slideFromBottomMobile;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .cookie-overlay .info {
    gap: 0.75em;
  }
  .cookie-overlay .cookie-buttons {
    flex-direction: column;
    gap: 0.825em;
  }
  .cookie-overlay .cookie-buttons button {
    font-size: 1em;
    width: 100%;
  }
}